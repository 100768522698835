const social = {
  es: {
    instagram: 'https://www.instagram.com/liftitapp/',
    facebook: 'https://www.facebook.com/liftit.co',
    linkedin: 'https://www.linkedin.com/company/liftit-app/'
  },
  co: {
    instagram: 'https://www.instagram.com/liftitapp/'
  },
  pt: {
    instagram: 'https://www.instagram.com/liftitapp/',
    facebook: 'https://www.facebook.com/LiftitBrasil',
    linkedin: 'https://www.linkedin.com/company/liftit-brasil/'
  }
}

export default social