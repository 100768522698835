import * as React from "react"
import Layout from 'components/layout'

// markup
const IndexPage = () => (
  <Layout>
    <main>
    </main>
  </Layout>
)


export default IndexPage
