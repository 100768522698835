import * as React from "react"
import Nav from 'components/nav'
import Footer from 'components/footer'

const Layout = ({ location, title, children, className='layout' }) => (
  <div id="main-container" className={className}>
    <Nav />

    <main>{children}</main>

    <Footer />
  </div>
)


export default Layout
