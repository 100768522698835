import React from "react";
import { Link } from "gatsby";
import Logo from "images/logo-liftit.svg";



// const LinkNav = ({ to, children }) => <Link to={to} activeClassName='active'>{children}</Link>

const Nav = () => {
  return (
    <nav id="main-navbar">
      {/* <div className="menu">
            { navObj.map((nav) =>
              nav.map((element) => <Link>{element.name}</Link>)
            )}
          </div> */}
      <div className="global-wrapper">
        <div className="side-left">
          <Link to="https://liftit.co" className="logo">
            <img src={Logo} className="logo" alt="liftit logo" />
          </Link>
          {/* <LinkNav to='/'>
            Servicios

            <div className='submenu'>
              <Link to={'/services/e-commerce'}>
                E-commerce
                <p>Soluciones y entregas para tu e-commerce</p>
              </Link>
            </div>
          </LinkNav>
          <LinkNav to='/blog'>Pricing</LinkNav>
          <LinkNav to='/product'>Product</LinkNav>
          <LinkNav to='/resources'>
            Resources
            <div className='submenu'>
              <Link to='/blog'>Blog</Link>
            </div>
          </LinkNav>
          <LinkNav to='/about'>Nosotros</LinkNav>
          <LinkNav to='/lifters'>Lifters</LinkNav> */}
        </div>

        {/* <div className='side-right'>
          <Link className='track' to='/'>Track your order</Link>
          <Link to='/'>Sign in</Link>
        </div> */}
      </div>
    </nav>
  );
};
export default Nav;
