import React from 'react'
import { Link } from 'gatsby'

import social from 'data/social'

import Facebook from 'images/footer/Facebook.svg'
import Instagram from 'images/footer/Instagram.svg'
import Linkedin from 'images/footer/Linkedin.svg'
// import Twitter from 'images/footer/Twitter.svg'

const Footer = () => (
  <footer>
    <div className="global-wrapper">
      <div className="left">
        <Link to='https://liftit.co' className='brand-legal'>Liftit © {new Date().getFullYear()} </Link>
        {/* <Link to='/legal/terms'>Terminos y Condiciones </Link>
        <Link to='/legal/policy'>Politicas de privacidad</Link> */}
      </div>
      <div className="right">
        <a href={social.es.facebook}><img src={Facebook} alt="Facebook Liftit"/></a>
        {/* <a href="https://twitter.com/"><img src={Twitter} alt="Twitter Liftit"/></a> */}
        <a href={social.es.linkedin}><img src={Linkedin} alt="Linkedin Liftit"/></a>
        <a href={social.es.instagram}><img src={Instagram} alt="Instagram Liftit"/></a>
      </div>
    </div>
  </footer>
)

export default Footer;